<style lang="less" scoped>
.fearless-center {
  width: 100%;
  background: #070911;
  &-first {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }
    &-container {
      width: 76.7%;
      max-width: 1104px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -44%);
      p:nth-child(1) {
        font-size: 20px;
        font-weight: 400;
        color: rgba(249, 98, 47, 1);
        letter-spacing: 3px;
      }
      p:nth-child(2) {
        font-size: 55px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
      }
      p:nth-child(4) {
        font-size: 18px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        letter-spacing: 1px;
        margin-top: 260px;
      }
    }
  }
  &-second {
    width: 100%;
    height: 658px;
    background: #070911;
    position: relative;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: space-between;
      &-left {
        width: 49.8%;
        img {
          width: 100%;
        }
      }
      &-right {
        width: 42.2%;
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        letter-spacing: 4px;
        p {
          margin: 0;
          &:nth-child(1) {
            font-size: 30px;
            line-height: 42px;
          }
          &:nth-child(2) {
            font-size: 10px;
            line-height: 14px;
            margin: 5px 0 28px;
          }
          &:nth-child(3) {
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 1px;
            margin-bottom: 90px;
          }
        }
      }
    }
  }
  &-third {
    width: 100%;
    height: 680px;
    background: #0f1118;
    position: relative;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      margin: 0 auto;
      font-family: "PingFangSC-Regular", "PingFang SC";
      font-weight: 400;
      color: rgba(222, 230, 255, 1);
      padding-top: 60px;
      &-title {
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 4px;
      }
      &-sub-title {
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 4px;
        margin-bottom: 30px;
      }
      &-main {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &-item {
          width: 30%;
          margin-top: 41px;
          text-align: center;
          img {
            width: 50px;
            margin: 0 21px;
          }
          p {
            font-family: "PingFangSC-Regular", "PingFang SC";
            font-weight: 400;
            color: rgba(222, 230, 255, 1);
            &:nth-child(2) {
              font-size: 20px;
              line-height: 28px;
            }
            &:nth-child(3) {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  &-forth {
    width: 100%;
    height: 1312px;
    position: relative;
    margin-top: 136px;
    &-container {
      width: 76.7%;
      // height: 76%;
      max-width: 1104px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &-item {
        width: 30%;
        background: rgba(15, 17, 24, 1);
        margin-top: 90px;
        img {
          width: 100%;
        }
        .second-second {
          padding: 34px;
          color: rgba(222, 230, 255, 1);
          p:nth-child(1) {
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
          }
          p:nth-child(2) {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
          }
          p:nth-child(3) {
            width: 90px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 12px;
            line-height: 30px;
            border-radius: 4px;
            border: 1px solid rgba(222, 230, 255, 1);
            cursor: pointer;
          }
        }
      }
    }
  }
  .r-button {
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    letter-spacing: 0;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }
}
</style>

<template>
  <div class="fearless-center">
    <div class="fearless-center-first">
      <img src="../assets/home_fearless.png"
           alt="">
      <div class="fearless-center-first-container">
        <p>
          SHANGHAI WUWEI CENTRE
        </p>
        <p>
          无畏 <br />
          创意经济区域合作促进中心
        </p>
        <div class="r-button"
             @click="toAboutUsDetails">
          {{$t('readMore')}}
        </div>
        <p>
          战略合作： 荷兰罗斯加德工作室 /Normal Studio /Lucion Média /MASSIVart /Playmind /L'Eloi
        </p>
      </div>
    </div>
    <div class="fearless-center-second">
      <div class="fearless-center-second-container">
        <div class="fearless-center-second-container-left">
          <img src="../assets/fearless/fearless_2.png"
               alt="">
        </div>
        <div class="fearless-center-second-container-right">
          <p>我们是谁</p>
          <p>ABOUT Wuwei</p>
          <p>
            <!-- 上海无畏创意经济区域合作促进中心主要发起单位为长三角创意经济合作专业委员会，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开展工作的执行机构。由十一届全国政协副主席厉无畏担任促进中心名誉理事长。 -->
            上海无畏创意经济区域合作促进中心，由十一届全国政协副主席、“中国创意经济之父”厉无畏教授积极推进，长三角创意经济合作专业委员会牵头发起，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开展工作的执行机构。
          </p>
          <div class="r-button"
               @click="toAboutUsDetails">
            {{ $t('readMore') }}
          </div>
        </div>
      </div>
    </div>
    <div class="fearless-center-third">
      <div class="fearless-center-third-container">
        <div class="fearless-center-third-container-title">
          我们有什么
        </div>
        <div class="fearless-center-third-container-sub-title">
          OUR ADVANTAGE
        </div>
        <div class="fearless-center-third-container-main">
          <div class="fearless-center-third-container-main-item">
            <img src="../assets/fearless/i_1.png"
                 alt="">
            <p>
              各国世界级<br />
              设计周与艺术节
            </p>
            <p>
              巴黎设计周<br />
              Paris Design Week<br />
              巴塞罗那设计周<br />
              Barcelona Design Week<br />
              荷兰设计周<br />
              Dutch Designer Week<br />
              比利时设计周<br />
              DESIGNREGIO KORTRIJK
            </p>
          </div>
          <div class="fearless-center-third-container-main-item">
            <img src="../assets/fearless/i_2.png"
                 alt="">
            <p>
              数字设计<br />
              产品与项目
            </p>
            <p>
              我们已与<br />
              数字景观艺术设计装置提供者<br />
              ——荷兰罗斯加德工作室、<br />
              数字城市灯光秀设计装置提供者<br />
              ——Normal Studio；数字灯光互动<br />
              皮影秀设计装置提供者——<br />
              Lucion Média等诸多国际创意设计<br />
              公司或组织资源建立合作伙伴关系。
            </p>
          </div>
          <div class="fearless-center-third-container-main-item">
            <img src="../assets/fearless/i_3.png"
                 alt="">
            <p>
              欧洲顶尖创意组织<br />
              与创新机构
            </p>
            <p>
              世界创意经济区域合作国际组织 <br />
              DC network <br />
              比利时西佛兰德斯省创新思维研究中心 <br />
              Flanders Inshape <br />
              丹麦国家创新中心 <br />
              Innovation Centre Denmark <br />
              北欧食品技术创新实验室和孵化中心 <br />
              DTU SKYLAB FoodLab
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="fearless-center-forth">
      <div class="fearless-center-forth-container">
        <div class="fearless-center-forth-container-item">
          <div class="first-second">
            <img src="../assets/fearless/fearless_s1_1.png"
                 alt="">
          </div>
          <div class="second-second">
            <p>荷兰罗斯加德工作室</p>
            <p>
              罗斯加德工作室是荷兰艺术家兼创新者丹﹒罗斯加德的社会设计实验室。其多元化的设计领域将人类、科技和空间关系相融合，激发了人们无限的想象力。
            </p>
            <p @click="linkTo('https://www.studioroosegaarde.net/cn/info', '_blank')">
              {{$t('readMore')}}
            </p>
          </div>
        </div>
        <div class="fearless-center-forth-container-item">
          <div class="first-second">
            <img src="../assets/fearless/fearless_s1_2.png"
                 alt="">
          </div>
          <div class="second-second">
            <p>DC Network</p>
            <p>
              DC Network是比利时全球创意区域联盟组织，利用创造性思维促进创新产业。该组织每年都会举办一个关于创意、创新和创业的国际学术研讨会——世界创意论坛(CWF)。
            </p>
            <p @click="linkTo('https://districtsofcreativity.org/projects/dclivinglab/', '_blank')">
              {{$t('readMore')}}
            </p>
          </div>
        </div>
        <div class="fearless-center-forth-container-item">
          <div class="first-second">
            <img src="../assets/fearless/fearless_s1_3.png"
                 alt="">
          </div>
          <div class="second-second">
            <p>MASSIVart</p>
            <p>
              MASSIVart是一家全球性艺术咨询与制作机构，我们的宗旨是为现代艺术家创造并提供有趣项目，同时作为艺术与商业的桥梁带来前所未有的创造力。
            </p>
            <p @click="linkTo('https://massivart.com/projects/', '_blank')">
              {{$t('readMore')}}
            </p>
          </div>
        </div>
        <div class="fearless-center-forth-container-item">
          <div class="first-second">
            <img src="../assets/fearless/fearless_s1_4.png"
                 alt="">
          </div>
          <div class="second-second">
            <p>比利时创新思维研究中心</p>
            <p>
              由Flemish Industry于2006年建立，已被并入安特卫普管理学院。致力于研究可用性设计“User Centered Design”：用户是创新的灵感来源。
            </p>
            <p @click="linkTo('http://www.flandersinshape.be/en', '_blank')">
              {{$t('readMore')}}
            </p>
          </div>
        </div>
        <div class="fearless-center-forth-container-item">
          <div class="first-second">
            <img src="../assets/fearless/fearless_s1_5.png"
                 alt="">
          </div>
          <div class="second-second">
            <p>Normal Studio</p>
            <p>
              Normal Studio位于加拿大蒙特利尔，他们将自己定义为专注于沉浸式环境设计和制作的创意工作室。 在我们探索的实验室，视觉概念和技术之间的交流都是流畅的。
            </p>
            <p @click="linkTo('https://normal.studio/en/', '_blank')">
              {{$t('readMore')}}
            </p>
          </div>
        </div>
        <div class="fearless-center-forth-container-item">
          <div class="first-second">
            <img src="../assets/fearless/fearless_s1_6.png"
                 alt="">
          </div>
          <div class="second-second">
            <p>L'Eloi</p>
            <p>
              L'loi于1999年在蒙特利尔由生物学专业的艾洛伊·波尚创立。他们专注于艺术并用作品讲故事，以其在各类艺术中有突出表现的艺术家而闻名。
            </p>
            <p @click="linkTo('https://leloi.ca/en/', '_blank')">
              {{$t('readMore')}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <Common-activies></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import CommonActivies from '../components/common-activities';
import CommonFooter from '../components/common-footer';

export default {
  name: 'fearless-center',
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    toAboutUsDetails () {
      this.$router.push({
        name: 'wuwei/details'
      })
    },
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    }
  },
  components: {
    CommonActivies,
    CommonFooter
  },
}
</script>
